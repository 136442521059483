.reports-download-cta {
  text-align: center;
  cursor: pointer;
}

.download-report-icon {
  width: 16px;
  height: 35px;
}

.report-download-expired {
  color: #d87b7b;
  font-weight: 800;
}
